import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import { RecoilRoot, useRecoilState } from 'recoil';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { authState, IAuthState } from './atoms/authState';
import { config } from './config';
import { decodeToken, isExpired } from 'react-jwt';
import { ConfigProvider, theme } from 'antd';
import koKR from 'antd/es/locale/ko_KR';

import 'antd/dist/reset.css';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import OfficeLayout from './components/OfficeLayout';
import { themeState } from './atoms/themeState';

const Statistics = React.lazy(() => import('./pages/Statistics'));
const Users = React.lazy(() => import('./pages/Users'));
const AllowedEmails = React.lazy(() => import('./pages/AllowedEmails'));
const InviteCodes = React.lazy(() => import('./pages/InviteCodes'));
const License = React.lazy(() => import('./pages/Licenses/Licenses'));
const Organizations = React.lazy(() => import('./pages/Organizations'));
const AppVersions = React.lazy(() => import('./pages/AppVersions'));
const Banners = React.lazy(() => import('./pages/Banners'));
const Notifications = React.lazy(() => import('./pages/Notifications'));
const Managers = React.lazy(() => import('./pages/Managers'));
const Products = React.lazy(() => import('./pages/Products'));
const Login = React.lazy(() => import('./pages/Login'));
const SiteOptions = React.lazy(() => import('./pages/SiteOptions'));
const Slangs = React.lazy(() => import('./pages/Slangs'));
const FunctionGroups = React.lazy(() => import('./pages/FunctionGroups'));
const Phrases = React.lazy(() => import('./pages/Phrases/Phrases'));
const UserSubscriptions = React.lazy(() => import('./pages/Subscriptions/UserSubscriptions'));

export const queryClient = new QueryClient();

const { defaultAlgorithm, darkAlgorithm } = theme;

function AppRoute() {
  const navigate = useNavigate();
  const [auth, setAuth] = useRecoilState(authState);
  const [theme] = useRecoilState(themeState);

  const isRoot = useMatch('/');

  const { isLogged } = auth;

  useEffect(() => {
    // 1. 로그인 상태 확인
    if (!isLogged) {
      // 2. 토큰 유무 확인.
      const accessToken = localStorage.getItem(config.apiToken);
      if (accessToken) {
        const isApiTokenExpired = isExpired(accessToken);
        // API 토큰 유효함 (로그인 진행)
        if (!isApiTokenExpired) {
          const myDecodedToken = decodeToken(accessToken) as {
            manager: IAuthState;
          };

          setAuth({
            ...myDecodedToken.manager,
            isLogged: true,
          });

          if (isRoot) {
            navigate('/statistics');
          }
        }
      } else {
        navigate('/login');
      }
    }
  }, [navigate, setAuth, isLogged]);

  return (
    <ConfigProvider
      locale={koKR}
      theme={{
        algorithm: theme === 'dark' ? darkAlgorithm : defaultAlgorithm,
        token: {},
      }}
    >
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<OfficeLayout />}>
          <Route path="statistics" element={<Statistics />} />
          <Route path="allowed-emails" element={<AllowedEmails />} />
          <Route path="invite-codes" element={<InviteCodes />} />
          <Route path="license" element={<License />} />
          <Route path="users" element={<Users />} />
          <Route path="organizations" element={<Organizations />} />
          <Route path="app-versions" element={<AppVersions />} />
          <Route path="banners" element={<Banners />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="products" element={<Products />} />
          <Route path="managers" element={<Managers />} />
          <Route path="site-options" element={<SiteOptions />} />
          <Route path="slangs" element={<Slangs />} />
          <Route path="function-groups" element={<FunctionGroups />} />
          <Route path="phrases/:type" element={<Phrases />} />
          <Route path="user-subscriptions" element={<UserSubscriptions />} />
        </Route>
      </Routes>
    </ConfigProvider>
  );
}

function App() {
  return (
    <HelmetProvider>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <BrowserRouter>
            <AppRoute />
          </BrowserRouter>
        </QueryClientProvider>
      </RecoilRoot>
    </HelmetProvider>
  );
}

export default App;
