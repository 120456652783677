import {
  AppstoreOutlined,
  AreaChartOutlined,
  AuditOutlined,
  BarcodeOutlined,
  BellOutlined,
  CreditCardOutlined,
  FileWordOutlined,
  FlagOutlined,
  FrownOutlined,
  FunctionOutlined,
  HighlightOutlined,
  IdcardOutlined,
  MobileOutlined,
  NotificationOutlined,
  SettingOutlined,
  ShoppingOutlined,
  TeamOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Layout, Menu, MenuProps, Space } from 'antd';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import React, { Suspense, useMemo, useState } from 'react';
import OfficeContentLoading from '../OfficeContentLoading';
import OfficeLayoutProfile from './OfficeLayoutProfile';

const { Content, Sider } = Layout;

const mainMenus = {
  statistics: {
    key: '통계',
    label: '통계',
    icon: <AreaChartOutlined />,
    url: '/statistics',
  },
  user: {
    key: '회원',
    label: '회원',
    icon: <IdcardOutlined />,
    url: '',
  },
  inviteCode: {
    key: '초대 코드',
    label: '초대 코드',
    url: '/invite-codes',
    icon: <BarcodeOutlined />,
  },
  userManage: {
    key: '회원 관리',
    label: '회원 관리',
    url: '/users',
    icon: <UserOutlined />,
  },
  license: {
    key: '라이선스',
    label: '라이선스',
    url: '/license',
    icon: <CreditCardOutlined />,
  },
  organization: {
    key: '워크스페이스',
    label: '워크스페이스',
    url: '/organizations',
    icon: <TeamOutlined />,
  },
  purchase: {
    key: '구매',
    label: '구매',
    icon: <ShoppingOutlined />,
    url: '',
  },
  paymentProduct: {
    key: '상품',
    label: '상품',
    url: '/products',
    icon: <WalletOutlined />,
  },
  userSubscription: {
    key: '구독 관리',
    label: '구독 관리',
    url: '/user-subscriptions?sortKey=status&sortDirection=ASC',
    icon: <ShoppingOutlined />,
  },
  appManage: {
    key: '앱',
    label: '앱',
    icon: <MobileOutlined />,
    url: '',
  },
  appVersion: {
    key: '앱 버전',
    label: '앱 버전',
    url: '/app-versions',
    icon: <AppstoreOutlined />,
  },
  slangs: {
    key: '비속어',
    label: '비속어',
    url: '/slangs',
    icon: <FrownOutlined />,
  },
  banner: {
    key: '배너',
    label: '배너',
    url: '',
    icon: <FlagOutlined />,
  },
  mobileBanner: {
    key: 'mobileBanner',
    label: '모바일',
    url: '/banners?platform=mobile&type=all&activated=all',
    icon: <FlagOutlined />,
  },
  webBanner: {
    key: 'webBanner',
    label: '웹',
    url: '/banners?platform=web&type=all&activated=all',
    icon: <FlagOutlined />,
  },
  notification: {
    key: '알림',
    label: '알림',
    url: '',
    icon: <NotificationOutlined />,
  },
  notificationList: {
    key: 'notificationList',
    label: '알림 목록',
    url: '/notifications',
    icon: <BellOutlined />,
  },
  manage: {
    key: '관리',
    label: '관리',
    icon: <SettingOutlined />,
    url: '',
  },
  manager: {
    key: '관리자',
    label: '관리자',
    url: '/managers',
    icon: <SettingOutlined />,
  },
  siteOption: {
    key: '약관 관리',
    label: '약관 관리',
    url: '/site-options',
    icon: <AuditOutlined />,
  },
  functionGroup: {
    key: '기능 그룹',
    label: '기능 그룹',
    url: '/function-groups',
    icon: <FunctionOutlined />,
  },
  highlightPhrase: {
    key: '하이라이트 사전',
    label: '하이라이트 사전',
    url: '/phrases/highlight',
    icon: <HighlightOutlined />,
  },
  boostingWordPhrase: {
    key: '단어장 사전',
    label: '단어장 사전',
    url: '/phrases/boosting_word',
    icon: <FileWordOutlined />,
  },
};

const mainMenuItems = [
  {
    ...mainMenus.statistics,
  },
  {
    ...mainMenus.user,
    children: [
      // mainMenus.allowedEmail,
      mainMenus.inviteCode,
      mainMenus.userManage,
      mainMenus.license,
      mainMenus.organization,
    ],
  },
  {
    ...mainMenus.purchase,
    children: [mainMenus.paymentProduct, mainMenus.userSubscription],
  },
  {
    ...mainMenus.notification,
    children: [mainMenus.notificationList],
  },
  {
    ...mainMenus.appManage,
    children: [mainMenus.appVersion, mainMenus.slangs],
  },
  {
    ...mainMenus.banner,
    children: [mainMenus.mobileBanner, mainMenus.webBanner],
  },
  {
    ...mainMenus.manage,
    children: [
      mainMenus.manager,
      mainMenus.siteOption,
      mainMenus.functionGroup,
      mainMenus.highlightPhrase,
      mainMenus.boostingWordPhrase,
    ],
  },
];

const defaultOpenKeys = mainMenuItems.map((mainMenuitem) => mainMenuitem.key);

function OfficeLayout() {
  const navigate = useNavigate();
  let location = useLocation();
  let [searchParams] = useSearchParams();

  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState(defaultOpenKeys);

  const menuArray = Object.values(mainMenus);

  const items = mainMenuItems;

  const onSelect = (data: any) => {
    navigate(data.item.props.url);
  };

  const selectedKeys = useMemo(() => {
    if (
      location.pathname === '/banners' &&
      searchParams.get('platform') === 'mobile'
    ) {
      return ['mobileBanner'];
    } else if (
      location.pathname === '/banners' &&
      searchParams.get('platform') === 'web'
    ) {
      return ['webBanner'];
    }

    const selectedMenu = menuArray.find(
      (menu) => menu.url === location.pathname,
    );
    return selectedMenu ? [selectedMenu.key] : [];
  }, [location, menuArray, searchParams]);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    setOpenKeys(keys);
  };

  const onCollapse = (value: boolean) => {
    if (value) {
      setOpenKeys([]);
    }

    setCollapsed(value);
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <Space
          style={{
            height: 64,
            width: collapsed ? 80 : 200,
            padding: 16,
            position: 'fixed',
            zIndex: 1,
            background: '#002140',
          }}
        >
          <OfficeLayoutProfile collapsed={collapsed} />
        </Space>
        <Menu
          style={{
            paddingBottom: 100,
            paddingTop: 65,
          }}
          theme="dark"
          openKeys={openKeys}
          mode="inline"
          items={items}
          onSelect={onSelect}
          onOpenChange={onOpenChange}
          selectedKeys={selectedKeys}
        />
      </Sider>
      <Layout>
        <Content
          style={{
            margin: '0 16px',
            marginLeft: collapsed ? 95 : 215,
          }}
        >
          <Suspense fallback={<OfficeContentLoading />}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
}

export default OfficeLayout;
